<template>
  <div>
    <el-card class="box-card">
      <blockquote>
        <span
          >欢迎管理员：<el-tag>{{ name }}</el-tag></span
        >
        <span style="margin-left: 15px"
          >博客已正常运行：<el-tag>{{ runTime }}</el-tag></span
        >
      </blockquote>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>今日数据统计</span>
        <el-button style="float: right; padding: 3px 0" type="text"
          >操作按钮</el-button
        >
      </div>
      <el-row :gutter="10">
        <el-col :xs="12" :sm="6">
          <el-card class="box-card item">
            <h3>新增访问</h3>
            <p>
              {{ testData.todayVisitors }}
            </p>
          </el-card>
        </el-col>

        <el-col :xs="12" :sm="6">
          <el-card class="box-card item">
            <h3>新增请求</h3>
            <p>
              {{ testData.todayVisitorPaths }}
            </p>
          </el-card>
        </el-col>

        <el-col :xs="12" :sm="6">
          <el-card class="box-card item">
            <h3>当前在线</h3>
            <p>
              {{ testData.nowVisitors }}
            </p>
          </el-card>
        </el-col>

        <el-col :xs="12" :sm="6">
          <el-card class="box-card item">
            <h3>新增留言</h3>
            <p>
              {{ testData.todayComments }}
            </p>
          </el-card>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>历史数据</span>
        <el-button style="float: right; padding: 3px 0" type="text"
          >操作按钮</el-button
        >
      </div>
      <el-row :gutter="10">
        <el-col :xs="12" :sm="6">
          <el-card class="box-card item">
            <h3>累计访客</h3>
            <p>
              {{ testData.allVisitors }}
            </p>
          </el-card>
        </el-col>

        <el-col :xs="12" :sm="6">
          <el-card class="box-card item">
            <h3>累计请求</h3>
            <p>
              {{ testData.allVisitorPaths }}
            </p>
          </el-card>
        </el-col>

        <el-col :xs="12" :sm="6">
          <el-card class="box-card item">
            <h3>累计留言</h3>
            <p>
              {{ testData.allComments }}
            </p>
          </el-card>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>随机一图</span>
      </div>
      <div>
        <img class="todyimg" :src="todayimg" />
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "ShowMain",
  data() {
    return {
      name: "Yaimer",
      initTime: "2021/4/20 00:00:00",
      runTime: "191天11小时12秒",
      todayimg: "",
      testData: {
        nowVisitors: 2,
        todayVisitors: 10,
        todayVisitorPaths: 1042,
        todayComments: 1,
        allVisitors: 325,
        allVisitorPaths: 32540,
        allComments: 56,
      },
      t1:null
    };
  },
  created() {
    //定时更新 最新运行时间
    const that = this;
    this.t1 = window.setInterval(function () {
      that.runTime = that.common.changetime(new Date(that.initTime));
    }, 1000);
    this.getImg(that);
    this.getData(that);
  },
  beforeDestroy(){
    window.clearInterval(this.t1);
  },
  methods: {
    getData(that) {
      this.$axios
        .get("/adminApi/indexData")
        .then(function (resp) {
          that.testData = resp.data.data;
        });
    },
    getImg(that) {
      this.$axios
        .get("https://api.xiakai.online/anime?type=json", {
          withCredentials: false,
        })
        .then(function (resp) {
          that.todayimg = resp.data.data.url;
        });
    },
  },
};
</script>

<style scoped>
.item {
  border-radius: 6px !important;
  display: block;
  background-color: #f8f8f8;
  color: #999;
  border-radius: 2px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.item:hover {
  cursor: pointer;
  background-color: #cfcfcf;
  color: #888;
}
.item h3 {
  padding-bottom: 10px;
  font-size: 12px;
}
.item p {
  font-style: normal;
  font-size: 30px;
  font-weight: 300;
  color: #009688;
}
.todyimg {
  display: inline-block;
  max-width: 50%;
  height: auto;
}
</style>